import authAxios from "./config/need-auth-axios";

class rolesService {
  getRoles() {
    return authAxios.get("/admin/role/all/rd");
  }
  getAllSections() {
    return authAxios.get("/admin/role/section/all/rd");
  }
  getAllEmployee(employeeFullName = null, employeeUserName = null, merchantId) {
    return authAxios.post("/admin/employee/all/rd", {
      name: employeeFullName,
      username: employeeUserName,
      merchantId: merchantId,
      pageableDTO: {
        size: 40,
        page: 0,
        direction: "ASC",
        sortBy: "id"
      }
    });
  }
  employeeRolesRemove(id, role, merchantId) {
    return authAxios.post("/admin/employee/remove/role/wr", {
      merchantId: merchantId,
      role: {
        role: role
      },
      employee: {
        id: id
      }
    });
  }
  employeeRolesAdd(id, role, merchantId) {
    return authAxios.post("/admin/employee/add/role/wr", {
      merchantId: merchantId,
      role: {
        role: role
      },
      employee: {
        id: id
      }
    });
  }
  getUsersRole(id, merchantId, currentPage) {
    const page = currentPage - 1
    return authAxios.post("/admin/role/merchants/all/rd", {
      id: id,
      merchantId: merchantId,
      pageableDTO: {
        size: 20,
        page,
        direction: "ASC",
        sortBy: "id"
      }
    });
  }
  createNewRole(roleName, merchantId) {
    return authAxios.post("/admin/role/add/wr", {
      role: roleName,
      merchantId: merchantId,
      permissions: {}
    });
  }
  rolesUpdate(role, permissions, merchantId) {
    return authAxios.post("/admin/role/update/wr", {
      role: role,
      permissions: permissions,
      merchantId: merchantId
    });
  }
  rolesRemove(id, merchantId) {
    return authAxios.post("/admin/role/remove/wr", {
      id: id,
      merchantId: merchantId
    });
  }
}
export default new rolesService();
