import { mapGetters } from "vuex";
import * as types from "../../../../store/types";
import rolesService from "../../../../services/rolesService";
import $renderDate from "./../../../../mixins/renderDate";

export default {
  name: "roles",
  components: {},
  mixins: [$renderDate],
  props: [],
  data() {
    return {
      currentPage: 1,
      roles: "",
      newRoleName: "",
      allSections: "",
      usersRole: "",
      tableHeaders: ["عنوان", "تاریخ ایجاد", "کاربران", "دسترسی", "حذف"]
    };
  },
  computed: {
    ...mapGetters({
      userInfo: types.GET_USER_INFO
    })
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    // get Roles
    getRoles() {
      rolesService.getRoles().then(Response => {
        this.roles = Response.data;
      });
      rolesService.getAllSections().then(Response => {
        this.allSections = Response.data;
      });
    },
    // Add new Role
    createNewRole() {
      rolesService
        .createNewRole(this.newRoleName, this.userInfo.merchantId)
        .then(Response => {
          if (Response) {
            this.$bvToast.toast(Response.message, {
              title: " ",
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "successBackgroundColor successFontColor rtl text-right",
              headerClass:
                "successBackgroundColor successFontColor  rtl text-right",
              solid: false
            });
            this.getRoles();
            setTimeout(() => {
              this.$bvModal.show("modal-Edit" + Response.data.id);
            }, 1000);
          }
        })
        .catch(Response => {
          if (Response.data) {
            this.$bvToast.toast(Response.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          }
        });
    },
    // Show Users Role
    getUsersRole(id) {
      rolesService
        .getUsersRole(id, this.userInfo.merchantId, this.currentPage)
        .then(Response => {
          this.usersRole = Response.data;
          this.$bvModal.show("modal-Users" + id);
        });
    },
    // Update Roles permission
    update(item) {
      rolesService
        .rolesUpdate(item.role, item.permissions, this.userInfo.merchantId)
        .then(Response => {
          if (Response.data) {
            this.$bvModal.hide("modal-Edit" + item.id);
            this.$bvToast.toast(Response.message, {
              title: " ",
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "successBackgroundColor successFontColor rtl text-right",
              headerClass:
                "successBackgroundColor successFontColor  rtl text-right",
              solid: false
            });
            this.getRoles();
          }
        })
        .catch(Response => {
          if (Response.data) {
            this.$bvToast.toast(Response.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          }
        });
    },

    // remove Role
    remove(id) {
      rolesService
        .rolesRemove(id, this.userInfo.merchantId)
        .then(Response => {
          if (Response.data) {
            this.getRoles();
            this.$bvModal.hide("modal-Edit" + id);
          }
        })
        .catch(Response => {
          if (Response.data) {
            this.$bvToast.toast(Response.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          }
        });
    }
  }
};
